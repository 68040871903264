import { z } from 'zod'

export const auditEventTypeAction = 'CompanyAction'

export enum AuditEventEvent {
  Created = 'created',
  Changed = 'changed',
  Deleted = 'deleted',
}

export const auditEventTargetSchema = z.strictObject({
  /**
   * Backend class name of the model that was affected
   */
  type: z.string(),
  /**
   * ID for the specific instance of `targetType` that was affected
   */
  id: z.number(),
  /**
   * Textual representation of the target object at the time this event was created
   */
  text: z.string().nullable(),
})

export const auditEventSchema = z.strictObject({
  id: z.number(),
  /**
   * Identifies the user who triggered the event
   */
  userId: z.number(),
  /**
   * Path to the model that was affected, in increasing levels of depth, e.g. company action -> subtask
   */
  targetPath: z.array(auditEventTargetSchema),
  event: z.nativeEnum(AuditEventEvent),
  /**
   * Use in combination with `targetPath.at(-1).type` to uniquely identify the field that was changed,
   * e.g. for mapping to translations. Only relevant for {@link AuditEventEvent.Changed} events.
   */
  property: z.string().nullable(),
  /**
   * Only relevant for {@link AuditEventEvent.Changed} events
   */
  oldValue: z.string().nullable(),
  /**
   * Only relevant for {@link AuditEventEvent.Changed} events
   */
  newValue: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type AuditEvent = z.infer<typeof auditEventSchema>
